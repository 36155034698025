import axios from "axios";

export function getCancellationReasonCodes(type) {
  return (dispatch) => {
    let res = [
      //   {
      //     code: "Invalid_Prescription",
      //     reason: "Expired/Invalid prescription given.",
      //     type: "order",
      //   },
      //   {
      //     code: "Fraud_Prescription",
      //     reason: "Fraudulent prescription shared.",
      //     type: "order",
      //   },
      //   {
      //     code: "Reason_not_listed",
      //     reason: "Reason is not listed.",
      //     type: "order",
      //   },
      {
        code: "Order_Item_Unavailable",
        reason: "1 or more items unavailable",
        type: "order",
      },
      {
        code: "Staff_Unavailability	",
        reason: "Delivery boy/ Packer not available.",
        type: "order",
      },
      { code: "Trader_Order", reason: "Trader Order.", type: "order" },
      {
        code: "Customer_Request_Cancel_Order",
        reason: "Customer requested order cancel.",
        type: "order",
      },
      {
        code: "Customer_Request_Update_Order",
        reason: "Customer requested order modification.",
        type: "order",
      },
      {
        code: "DELIVERY_PINCODE_UNSERVICEABLE",
        reason: "Address not deliverable.",
        type: "order",
      },

      {
        code: "BUYER_NOT_FOUND",
        reason: "Customer not found at given location.",
        type: "order",
      },
      {
        code: "ADDRESS_NOT_FOUND",
        reason: "Customer's address is not found.",
        type: "order",
      },
      {
        code: "BUYER_NOT_AVAILABLE",
        reason: "Customer not available at location.",
        type: "order",
      },
      {
        code: "BUYER_REFUSED_DELIVERY",
        reason: "Delivery refused by customer.",
        type: "order",
      },
      {
        code: "BUYER_NO_CONTACT",
        reason: "No customer contact at location.",
        type: "order",
      },
      {
        code: "DELIVERY_VEHICLE_ISSUES",
        reason: "Vehicle issues - unable to deliver.",
        type: "order",
      },
      {
        code: "WEATHER_RELATED_ISSUES",
        reason: "Weather issues - unable to deliver.",
        type: "order",
      },
      {
        code: "SUSPICIOUS_ORDER_ACTIVITY",
        reason: "Fraudulent customer activity suspected.",
        type: "order",
      },
      {
        code: "Reason_not_listed",
        reason: "Reason is not listed",
        type: "order",
      },
      //   { code: "", reason: "", type: "order" },
      //   { code: "", reason: "", type: "order" },
      //   { code: "", reason: "", type: "order" },
      //   { code: "", reason: "", type: "order" },
      //   { code: "", reason: "", type: "order" },
      //   { code: "", reason: "", type: "order" },
      //   { code: "", reason: "", type: "order" },
      //   { code: "", reason: "", type: "order" },
      //   { code: "", reason: "", type: "order" },
    ];
    return res.filter((f) => f.type === type);
    // return axios
    // .get('https://ondcposible-gywazye4bq-uc.a.run.app/cancellationreason')
    // .then(res => {
    //     // console.log(res);
    //     return res.data;
    // })
    // .catch(err => {
    //     console.log('getCancellationReasonCodes', err);
    // });
  };
}
