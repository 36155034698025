import * as actionTypes from "../actionTypes";
import * as constants from "../Constatnts";

const intialState = {
  Version: "1.5.54",
  // Device:constants.WORLDLINE_DEVICE,
  //  Device:constants.DEVICE_NONE,
  Device: constants.PINELABS_DEVICE,
  AndroidPackageName: "com.posible.app",
  // AndroidPackageName:'com.posible.posibleappdemo',//worldline
  PineLabsApplicationID: "dc30c06936434d8b86a07d23b18b7267", //development application id
  PineLabsApplicationID: "23a87ecc469e44348fe8f8b41d76c1e2", //production application id
  WhiteLabel: {
    Code: "PINCODE",
    HelpLineNo: "022 XXXX XXX",
    HelpLineEmailID: "support@pincode.com",
    Title: "Pincode Business App",
    PrimaryColor: "#01392E",
    FavIcon: "pincode.png",
    LoginLogoOption: "1",
    RegisterLogoOption: "1",
    HomePageLogoOption: "2",
    MenuLogoOption: "2",
    // LoginBackgroundCSS:"posible_login_bg",
    // RegisterBackgroundCSS:"posible_register_bg",
    // ForgotPasswordBackgroundCSS:"posible_forgotpassword_bg",
  },
  // WhiteLabel: {
  //   Code: "POSIBLE",
  //   HelpLineNo: "022 6282 2678",
  //   HelpLineEmailID: "support@posible.in",
  //   Title: "Posible",
  //   PrimaryColor: "#6e2d90",
  //   FavIcon: "posible.png",
  //   LoginLogoOption: "1",
  //   RegisterLogoOption: "1",
  //   HomePageLogoOption: "1",
  //   MenuLogoOption: "1",
  //   LoginBackgroundCSS: "posible_login_bg",
  //   RegisterBackgroundCSS: "posible_register_bg",
  //   ForgotPasswordBackgroundCSS: "posible_forgotpassword_bg",
  // },
  // WhiteLabel: {
  //   Code: "WRX",
  //   HelpLineNo: "## #### ####",
  //   HelpLineEmailID: "support@wrx.co.in",
  //   Title: "WRX",
  //   PrimaryColor: "#3E3E3F",
  //   FavIcon: "wrx.png",
  //   LoginLogoOption: "1",
  //   RegisterLogoOption: "1",
  //   HomePageLogoOption: "1",
  //   MenuLogoOption: "1",
  // },
  // WhiteLabel:{
  //     Code:"SMAPP",
  //     HelpLineNo:"022 6282 2678",
  //     HelpLineEmailID:"support@salonmanagement.app",
  //     Title:"Salon Management App",
  //     PrimaryColor:"#7836E3",
  //     FavIcon:"SMLogo.png",
  //     LoginLogoOption:"1",
  //     RegisterLogoOption:"1",
  //     HomePageLogoOption:"1",
  //     MenuLogoOption:"1",
  //     LoginBackgroundCSS:"",
  //     RegisterBackgroundCSS:"",
  //     ForgotPasswordBackgroundCSS:""
  // },
  CustomFields: [],
  TransactionTypes: [],
  ProductTaxes: [],
  TransactionTypes: [],
  ProductCategories: [],
  ProductSubCategories: [],
  ProductBrands: [],
  Products: [],
  PaymentTypes: [],
  PrintConfigs: [],
  ProductTags: [],
  Modifiers: [],
  ApplicableOffers: [],
  ActiveEmployees: [],
  ActiveMemberships: [],
  Keywords: [],
  SaleProductCategories: [],
  SaleProductSubCategories: [],
  PageFilters: [],
  AppBanners: [],
  HelpContent: "",
  ConnectionStatus: { Status: "connected" }, //Offline
  Customers: [],
  CountryConfig: {},
  Carousels: [],
  PageFilters: [],
  Device: "", //worldlinedevice
  SelectedMenu: "",
  SelectedSubMenu: "",
  NewOnlineOrder: "",
  PincodeCategories: [],
  PincodeSubCategories: [],
  PincodeBrands: [],
  PincodeProductTypes: [],
  PincodeUOMs: [],
  PincodeModels: [],
  PincodePackaging: [],
  PincodeBundling: [],
  Stores: [],
  UserStores: [],
  SelectedSalesChannel: "",
  SalesChannel: [],
};

export default function(state = intialState, action) {
  switch (action.type) {
    case actionTypes.STORE_RESET_CONFIG:
      return {
        ...state,
        SelectedMenu: "",
        SelectedSubMenu: "",
        CustomFields: [],
        TransactionTypes: [],
        ProductTaxes: [],
        TransactionTypes: [],
        ProductCategories: [],
        ProductSubCategories: [],
        ProductBrands: [],
        Products: [],
        PaymentTypes: [],
        PrintConfigs: [],
        ProductTags: [],
        Modifiers: [],
        Customers: [],
        ApplicableOffers: [],
        ActiveEmployees: [],
        ActiveMemberships: [],
        Keywords: [],
        SaleProductCategories: [],
        SaleProductSubCategories: [],
        PageFilters: [],
        AppBanners: [],
        HelpContent: "",
        ConnectionStatus: { Status: "connected" },
        Carousels: [],
        PageFilters: [],
        NewOnlineOrder: "",
        PincodeCategories: [],
        PincodeSubCategories: [],
        PincodeBrands: [],
        PincodeProductTypes: [],
        PincodeUOMs: [],
        PincodeModels: [],
        PincodePackaging: [],
        PincodeBundling: [],
        Stores: [],
        SelectedSalesChannel: "",
        SalesChannel: [],
      };
    case actionTypes.SET_SELECTED_SALES_CHANNEL:
      return { ...state, SelectedSalesChannel: action.selectedSalesChannel };
    case actionTypes.SET_SALES_CHANNEL:
      return { ...state, SalesChannel: action.salesChannel };
    case actionTypes.CONFIG_SET_NEW_ONLINE_ORDER:
      // console.log("action.newOnlineOrder", action.newOnlineOrder);
      return { ...state, NewOnlineOrder: action.newOnlineOrder };
    case actionTypes.CONFIG_PAGEFILTERS:
      return { ...state, PageFilters: action.pageFilters };
    case actionTypes.CONFIG_GET_CAROUSELS:
      return { ...state, Carousels: action.carousels };
    case actionTypes.SIGN_OUT:
      return {
        ...state,
        CustomFields: [],
        TransactionTypes: [],
        ProductTaxes: [],
        TransactionTypes: [],
        ProductCategories: [],
        ProductSubCategories: [],
        ProductBrands: [],
        Products: [],
        PaymentTypes: [],
        PrintConfigs: [],
        ProductTags: [],
        Modifiers: [],
        Customers: [],
        ApplicableOffers: [],
        ActiveEmployees: [],
        ActiveMemberships: [],
        Keywords: [],
        SaleProductCategories: [],
        SaleProductSubCategories: [],
        PageFilters: [],
        AppBanners: [],
        HelpContent: "",
        ConnectionStatus: { Status: "connected" },
      };
    case actionTypes.CONFIG_SET_SELECTED_MENU:
      return { ...state, SelectedMenu: action.selectedMenu };
    case actionTypes.CONFIG_SET_SELECTED_SUB_MENU:
      return { ...state, SelectedSubMenu: action.selectedSubMenu };
    case actionTypes.CONFIG_GET_CUSTOM_FIELDS:
      return { ...state, CustomFields: action.customFields };
    case actionTypes.GET_TAXES:
      return { ...state, ProductTaxes: action.productTaxes };
    case actionTypes.PRODUCT_CATEGORY_GET:
      return { ...state, ProductCategories: action.productCategories };
    case actionTypes.PRODUCT_SUB_CATEGORY_GET:
      return { ...state, ProductSubCategories: action.productSubCategories };
    case actionTypes.PRODUCT_BRANDS_GET:
      return { ...state, ProductBrands: action.productBrands };
    case actionTypes.TRANSACTION_TYPES_GET:
      return { ...state, TransactionTypes: action.transactionTypes };
    case actionTypes.GET_ACTIVE_PRODUCTS:
      return { ...state, Products: action.products };
    case actionTypes.PAYMENT_TYPES_GET:
      return { ...state, PaymentTypes: action.paymentTypes };
    case actionTypes.GET_ACTIVE_CUSTOMERS:
      return { ...state, Customers: action.customers };
    case actionTypes.PRINT_CONFIG_GET:
      return { ...state, PrintConfigs: action.printConfigs };
    case actionTypes.PUSH_PRINT_CONFIG_TO_REDUX:
      let printConfigs = state.PrintConfigs;
      const index2 = state.PrintConfigs.map((e) => e.key).indexOf(
        action.printConfig.key
      );
      if (index2 >= 0) {
        printConfigs[index2] = action.printConfig;
      } else {
        printConfigs.push(action.printConfig);
      }
      return { ...state, PrintConfigs: printConfigs };
    case actionTypes.PUSH_PRODUCT_TO_REDUX:
      let products = state.Products;
      const index = state.Products.map((e) => e.key).indexOf(
        action.product.key
      );
      if (index >= 0) {
        products[index] = action.product;
      } else {
        products.push(action.product);
      }
      return { ...state, Products: products };
    case actionTypes.PUSH_PRODUCT_CATEGORIES_TO_REDUX:
      let productCategories = state.ProductCategories;
      productCategories.push(action.productCategory);
      return { ...state, ProductCategories: productCategories };
    case actionTypes.PUSH_PRODUCT_SUB_CATEGORIES_TO_REDUX:
      let productSubCategories = state.ProductSubCategories;
      productSubCategories.push(action.productSubCategory);
      return { ...state, ProductSubCategories: productSubCategories };
    case actionTypes.PUSH_PRODUCT_BRAND_TO_REDUX:
      let ProductBrands = state.ProductBrands;
      ProductBrands.push(action.productBrand);
      return { ...state, ProductBrands: ProductBrands };
    case actionTypes.PRODUCT_TAGS_GET:
      return { ...state, ProductTags: action.productTags };
    case actionTypes.MODIFIERS_GET:
      return { ...state, Modifiers: action.modifiers };
    case actionTypes.GET_APPLICABLE_OFFERS:
      return { ...state, ApplicableOffers: action.applicableOffers };
    case actionTypes.GET_ACTIVE_EMPLOYEES:
      return { ...state, ActiveEmployees: action.activeEmployees };
    case actionTypes.GET_ACTIVE_MEMBERSHIPS:
      return { ...state, ActiveMemberships: action.activeMemberships };
    case actionTypes.CONFIG_GET_KEYWORDS:
      return { ...state, Keywords: action.keywords };
    case actionTypes.GET_SALES_PRODUCTS:
      return { ...state, SaleProductCategories: action.salesProductCategories };
    case actionTypes.GET_SALES_SUB_PRODUCTS:
      return {
        ...state,
        SaleProductSubCategories: action.salesProductSubCategories,
      };
    case actionTypes.PUSH_PAGE_FILTERS_TO_REDUX:
      return { ...state, PageFilters: action.pageFilters };
    case actionTypes.CONFIG_APP_BANNER:
      return { ...state, AppBanners: action.appBanners };
    case actionTypes.CONFIG_APP_HELP_CONTENT:
      return { ...state, HelpContent: action.helpContent };
    //Offline
    case actionTypes.GET_CONNECTION_STATUS:
      return { ...state, ConnectionStatus: action.connectionStatus };
    case actionTypes.CONFIG_COUNTRY_CONFIG:
      return { ...state, CountryConfig: action.countryConfig };
    case actionTypes.PUSH_CUSTOMER_TO_REDUX:
      let customers = state.Customers;
      const index1 = state.Customers.map((e) => e.key).indexOf(
        action.customer.key
      );
      if (index1 >= 0) {
        customers[index1] = action.customer;
      } else {
        customers.push(action.customer);
      }
      return { ...state, Customers: customers };
    case actionTypes.SET_PINCODE_CATEGORIES:
      return { ...state, PincodeCategories: action.pincodeCategories };
    case actionTypes.SET_PINCODE_SUB_CATEGORIES:
      return { ...state, PincodeSubCategories: action.pincodeSubCategories };
    case actionTypes.SET_PINCODE_BRANDS:
      return { ...state, PincodeBrands: action.pincodeBrands };
    case actionTypes.SET_PINCODE_PRODUCT_TYPES:
      return { ...state, PincodeProductTypes: action.pincodeProductTypes };
    case actionTypes.SET_PINCODE_UOMS:
      return { ...state, PincodeUOMs: action.pincodeUOMs };
    case actionTypes.SET_PINCODE_MODELS:
      return { ...state, PincodeModels: action.pincodeModels };
    case actionTypes.SET_PINCODE_PACKAGING:
      return { ...state, PincodePackaging: action.pincodePackaging };
    case actionTypes.SET_PINCODE_BUNDLING:
      return { ...state, PincodeBundling: action.pincodeBundling };
    case actionTypes.SET_STORES:
      return { ...state, Stores: action.stores };
    case actionTypes.SET_USER_STORES:
      return { ...state, UserStores: action.userStores };
    default:
      return state;
  }
}
