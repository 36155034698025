import React, { Component } from "react";
import Icon from "../../2/Controls/Icon";

class WidgetTotalSales extends Component {
  constructor({ stores, fromDate, toDate, widget }) {
    super();
    this.state = {
      total_sales: 0,
      stores: stores,
      fromDate: fromDate,
      toDate: toDate,
      widget: widget,
    };
    // console.log('widget',widget)
  }

  async componentDidMount() {
    this.loadData(this.props.stores, this.props.fromDate, this.props.toDate);
  }
  //
  loadData(stores, fromDate, toDate) {
    if (
      this.props.widget.hasOwnProperty("WidgetOptionIncludeTransactionTypes") &&
      this.props.widget.WidgetOptionIncludeTransactionTypes
    ) {
      // console.log(this.props.widget);
      this.props
        .getSalesBasedOnTransactionTypes(
          this.props.user.user.RegistrationID,
          stores,
          this.props.widget.WidgetOptionTransactionTypeIDs,
          fromDate,
          toDate
        )
        .then((result) => {
          this.setState({
            total_sales:
              this.props.widget.hasOwnProperty("WidgetOptionIncludeBalance") &&
              this.props.widget.WidgetOptionIncludeBalance
                ? result.searchResult.total_sales
                : result.searchResult.total_paid_sales,
          });
        });
    } else {
      this.props
        .getDashboardHeaders(
          this.props.user.user.RegistrationID,
          stores,
          "",
          "",
          fromDate,
          toDate
        )
        .then((result) => {
          // console.log("WidgetTotalSales result",JSON.stringify(result))
          this.setState({
            total_sales:
              this.props.widget.hasOwnProperty("WidgetOptionIncludeBalance") &&
              this.props.widget.WidgetOptionIncludeBalance
                ? result.searchResult.total_sales
                : result.searchResult.total_paid_sales,
          });
        });
    }
  }

  componentWillReceiveProps({ stores, fromDate, toDate }) {
    this.loadData(stores, fromDate, toDate);
  }

  render() {
    return (
      <div className="info-box info-blue">
        <div className="info-box-icon ">
          <Icon type="sales" />
        </div>
        <div className="info-box-content">
          <div className="info-box-text">
            {this.state.widget &&
            this.state.widget.hasOwnProperty("Title") &&
            this.state.widget.Title.trim() !== ""
              ? this.state.widget.Title
              : "Sales"}
          </div>
          <div className="info-box-number">
            {Number(this.state.total_sales).toFixed(0)}
          </div>
        </div>
      </div>
    );
  }
  // render() {
  //     return <div className="col-md-3 col-sm-6 col-xs-12">
  //         <div className="info-box" style={{ marginTop: "10px" }}>
  //             <span className="info-box-icon " style={{backgroundColor:this.state.widget && this.state.widget.hasOwnProperty('WidgetOptionLeftBlockColor') && this.state.widget.WidgetOptionLeftBlockColor.trim()!=="" ? this.state.widget.WidgetOptionLeftBlockColor : "#00a65a"}}>
  //             <i style={{color:"white"}} className={this.state.widget && this.state.widget.hasOwnProperty('WidgetOptionLeftBlockIcon') && this.state.widget.WidgetOptionLeftBlockIcon.trim()!=="" ? this.state.widget.WidgetOptionLeftBlockIcon : "fa fa-money"} ></i></span>
  //             <div className="info-box-content">
  //                 <span className="info-box-text" style={{ fontSize: "13px" }}>{this.state.widget && this.state.widget.hasOwnProperty('Title') && this.state.widget.Title.trim()!=="" ? this.state.widget.Title : "Sales"}</span>
  //                 <span className="info-box-number">{Number(this.state.total_sales).toFixed(0)}</span>
  //             </div>
  //         </div>
  //     </div>
  // }
}

export default WidgetTotalSales;
